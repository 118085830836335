import React from "react";
import FullPageLoader from "@/components/FullPageLoader";
import authService from "@/service/auth-service";

const Login: React.FC = () => {

  React.useEffect(() => {

    authService
      .login({
        redirectMethod: "replace",
      })
      .then((res) => {
        // console.log("res", res);
      })
      .catch((e) => {
        // console.log(e);
      });
  }, []);

  return <FullPageLoader />;
};

export default Login;
